import React, { useState } from "react";
import "./totalAccounts.scss";
import { Button, Col, Collapse, Row } from "antd";
import image from "../../../Assets/images/Credit Age.svg";
import "./panelHeader.scss";
import { CrifReport } from "../../../models/creditReport.model";
import { OptionArray } from "../../../shared/types/optionArray.type";
import { currencyFormatter } from "../../../shared/utils/formatter";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";
import { AccountsDetails } from "../../../models/accountsDetails.model";
import PaymentHistoryTable from "./PaymentTable";
import { convertStringForClassName, getCrifRating } from "../../../shared/utils/creditReportUtil";
import { toTitleCase } from '../../../shared/utils/titleCaseConverter';
import { RowAlignment } from "../../../enums/rowAlignment.type";
import { prefixZero } from '../utils/index';
import { CreditReportKeyFactorLabels } from "../KeyFactors/IndividualKeyFactor/constants";

interface TotalAccountsProps {
  data?: CrifReport;
}

const TotalAccounts = (props: TotalAccountsProps) => {
  const { data } = props;

  const { Panel } = Collapse;
  const [activeCollapseKeys, setActiveCollapseKeys] = useState<string[]>([]);

  const handleExpandAll = () => {
    setActiveCollapseKeys((prev) => {
      if (prev.length === 0) {
        return (
          data?.creditAccounts?.accountsDetails?.map((_, index) =>
            (index + 1).toString()
          ) || []
        );
      } else {
        return [];
      }
    });
  };

  const handlePanelChange = (keys: string | string[]) => {
    setActiveCollapseKeys(Array.isArray(keys) ? keys : [keys]);
  };

  const renderPanelContent = (account: AccountsDetails) => {
    const panelDetails: OptionArray[] = [
      {
        label: account.accountType,
        value: fillEmptyData(account.lender),
        key: "1",
      },
      {
        label: CreditReportKeyFactorLabels.ACCOUNT_NUMBER,
        value: fillEmptyData(account.accountNumber),
        key: "2",
      },
      {
        label: CreditReportKeyFactorLabels.ACCOUNT_HOLDER_TYPE,
        value: fillEmptyData(account.accountHolderType),
        key: "3",
      },
      {
        label: CreditReportKeyFactorLabels.LOAN_AMOUNT,
        value: currencyFormatter({
          amount: account.disbursedAmount,
          hasCurrencySign: true,
          shouldReturnNA: false
        }),
        key: "4",
      },
      { label: CreditReportKeyFactorLabels.LOAN_DISBURSED, value: fillEmptyData(account.disbursedDate) },
      {
        label: CreditReportKeyFactorLabels.WRITE_OFF_AMOUNT,
        value: currencyFormatter({
          amount: account.writeOffAmount,
          hasCurrencySign: true,
          shouldReturnNA: false
        }),
        key: "5",
      },
      {
        label: CreditReportKeyFactorLabels.STATUS,
        value: fillEmptyData(toTitleCase(account.accountStatus)),
        key: "6",
        className: convertStringForClassName(account.accountStatus)
      },
    ];

    const panelOptions: OptionArray[] = [
      {
        label: CreditReportKeyFactorLabels.STATUS,
        value: currencyFormatter({
          amount: account.currentBalance,
          hasCurrencySign: true,
          shouldReturnNA: false
        }),
        key: "1",
      },
      {
        label: CreditReportKeyFactorLabels.AMOUNT_PAID,
        value: currencyFormatter({
          amount: account.loanAmountPaid,
          hasCurrencySign: true,
          shouldReturnNA: false
        }),
        key: "2",
      },
      {
        label: CreditReportKeyFactorLabels.INSTALMENT_AMOUNT,
        value: currencyFormatter({
          amount: account.instalmentAmount,
          hasCurrencySign: true,
          shouldReturnNA: false
        }),
        key: "3",
      },
      {
        label: CreditReportKeyFactorLabels.DAYS_PAST_DUE,
        value: account.daysPastDue,
        key: "4",
      },
      {
        label: CreditReportKeyFactorLabels.LAST_UPDATED,
        value: fillEmptyData(account.reportedOn),
        key: "5",
      },
      {
        label: CreditReportKeyFactorLabels.INSTALMENT_FREQUENCY,
        value: fillEmptyData(account?.instalementFrequency),
        key: "6",
      },
    ];

    return (
      <div>
        <Row gutter={[16, 16]} className="panel-component-first">
          <Col span={2}>
            <img src={image} alt={CreditReportKeyFactorLabels.TOTAL_ACCOUNTS_LOGO} />
          </Col>
          {panelDetails.map((detail) => (
            <Col
              key={detail?.key}
              span={detail.label !== CreditReportKeyFactorLabels.ACCOUNT_HOLDER_TYPE ? 3 : 4}
              className="block__value flex-column"
            >
              <span className={`status--${convertStringForClassName(detail.className)}`}>{detail.value}</span>
              <span className="block__label text-capitalize">{detail.label}</span>
            </Col>
          ))}
        </Row>
        <Row gutter={[16, 16]} className="panel-header-second">
          <Col span={2}>
            <img src={image} alt="" className="hidden" />
          </Col>
          {panelOptions.map((detail) => (
            <Col
              key={detail?.key}
              span={detail.label !== CreditReportKeyFactorLabels.INSTALMENT_AMOUNT ? 3 : 4}
              className="block__value flex-column"
            >
              <span>{detail.value}</span>
              <span className="block__label text-capitalize">{detail.label}</span>
            </Col>
          ))}
        </Row>
      </div>
    );
  };

  const accountTables = data?.creditAccounts?.accountsDetails?.map(
    (account, index) => {
      return (
        <Panel key={index + 1} header={renderPanelContent(account)}>
          <PaymentHistoryTable account={account} />
        </Panel>
      );
    }
  );

  return (
    <div className="total-accounts-component">
      <div className="card">
        <Row align={RowAlignment.MIDDLE} className="header">
          <Col className="block__heading text-left" span={8}>
            {CreditReportKeyFactorLabels.TOTAL_ACCOUNTS}
          </Col>
          <Col span={3} className="block__heading text-left overall-score">
            <span>{fillEmptyData(data?.crifScore)}</span>
            <span className={`crif-badge crif-badge__good`}>
              {getCrifRating(data?.crifScore)}
            </span>
            <span className="overall-score__heading">{CreditReportKeyFactorLabels.OVERALL_SCORE}</span>
          </Col>
          <Col span={3} className="block__heading active-accounts flex-column">
            <span className="text-left">{fillEmptyData(prefixZero(data?.creditAccounts?.totalActiveAccounts))}</span>
            <span className="active-accounts__heading text-left">{CreditReportKeyFactorLabels.ACTIVE_ACCOUNTS}</span>
          </Col>
          <Col span={3} className="block__heading closed-accounts flex-column">
            <span className="text-left">{fillEmptyData(prefixZero(data?.creditAccounts?.totalClosedAccounts))}</span>
            <span className="text-left closed-accounts__heading">{CreditReportKeyFactorLabels.CLOSED_ACCOUNTS}</span>
          </Col>
          <Col span={3} className="block__heading flex-column total-accounts">
            <span className="text-left">{fillEmptyData(data?.creditAccounts?.totalCreditAccounts)}</span>
            <span className="text-left total-accounts__heading">{CreditReportKeyFactorLabels.CLOSED_ACCOUNTS}</span>
          </Col>
          <Col span={2}>
            <Button className="expand-all-button" onClick={handleExpandAll}>
              {activeCollapseKeys.length > 0 ? CreditReportKeyFactorLabels.COLLAPSE_ALL : CreditReportKeyFactorLabels.EXPAND_ALL}
            </Button>
          </Col>
        </Row>
        <Collapse
          className="mt-5 mb-5"
          activeKey={activeCollapseKeys}
          expandIconPosition="end"
          onChange={handlePanelChange}
        >
          {accountTables}
        </Collapse>
      </div>
    </div>
  );
};

export default TotalAccounts;
